// @ts-ignore
import { Elm } from './OctoVaultClient.elm';
import "./octovault-client.scss";
import {
    ExecuteSignedPayload,
    OctoVaultInitFn,
    OctoVaultInterface,
    OctoVaultOptions
} from "./spring-types-src/modules/octovault";


export const originFromLocation = function (location: Location): string {
    return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
};

const octoVaultInit: OctoVaultInitFn = function (options?: OctoVaultOptions): OctoVaultInterface | null {
    const placeHolder = document.getElementById("octovault-button");
    const compactLayout = placeHolder
        ? !!placeHolder.dataset.compact
        : false
    ;

    if (!placeHolder) {
        console.warn("No div with id 'octovault-button' was found.");
        return null;
    }

    const childDiv = document.createElement("div");
    placeHolder.appendChild(childDiv);
    const client : any = new Elm.OctoVaultClient.init({
        node: childDiv,
        flags: {
            origin: originFromLocation(window.location),
            gasRelayUrl: options && options.gasRelayUrl || null,
            compactLayout: compactLayout,
        }
    });
    let elmNodes: any = [];
    setInterval((): void => {
        if (elmNodes.length == 0) {
            const placeHolder = document.getElementById("octovault-button");
            elmNodes = placeHolder ? placeHolder.children : [];
        }
        if (elmNodes && !document.body.contains(elmNodes[0])) {
            const placeHolder = document.getElementById("octovault-button");
            if (!placeHolder) {
                console.warn("No div with id 'octovault-button' was found.");
            } else {
                placeHolder.appendChild(elmNodes[0]);
            }
        }
    }, 500);
    let serverFrame: any | undefined;

    let maxRequestId: number = 0;
    const callbacksDict: any = {};
    let selectedAccount: any = null;

    const accountListenerCallbacks : ((account: string) => void)[] = [];

    const octoVault: OctoVaultInterface = {
        addAccountListener(callback) {
            accountListenerCallbacks.push(callback);
        },
        sign: (msg: string, resolve: Function, reject: Function): void => {
            const thisRequestId = maxRequestId++;
            callbacksDict[thisRequestId] = { resolve, reject };
            client.ports.sign.send([thisRequestId, msg]);
        },
        executeSigned: (payload: ExecuteSignedPayload, resolve: Function, reject: Function): void => {
            const thisRequestId = maxRequestId++;
            callbacksDict[thisRequestId] = { resolve, reject };
            client.ports.executeSigned.send([thisRequestId, payload]);
        },
    };

    window.octoVault = octoVault;

    Object.defineProperty(octoVault, 'account', {
        get: function () {
            // We use a closure to make sure account cannot be modified from outside OctoVault.
            return selectedAccount;
        },
        set: function () {
            throw new Error("Account cannot be externally modified.");
        }
    });

    window.addEventListener("message", (event) => {
        client.ports.receiveMessage.send(event)
    }, false);

    client.ports.log && client.ports.log.subscribe(console.debug);

    client.ports.setAccount.subscribe((account: string) => {
        // console.log("Account is set to ", account);
        selectedAccount = account;

        accountListenerCallbacks.forEach(x => x(account))
    });

    client.ports.setIframe.subscribe((iframeId: string) => {
        serverFrame = document.getElementById(iframeId);
        if (!serverFrame) {
            throw new Error(`There is no iframe with id '${iframeId}'.`)
        }
        if (serverFrame.tagName.toLowerCase() !== "iframe") {

            throw new Error(`The element with id '${iframeId}' is not an iframe.`)
        }
    });

    client.ports.sendMessage.subscribe(([message, targetOrigin]: [string, string]) => {
        // console.log("message to send to iframe =", message);
        // console.log("targetOrigin =", targetOrigin);
        if (serverFrame) {
            serverFrame.contentWindow.postMessage(message, targetOrigin)
        } else {
            throw new Error("The serverFrame is not yet ready.")
        }
    });

    client.ports.signed.subscribe(([status, requestId, msg]: [string, string, string]) => {
        // console.log("signed", status, requestId, msg)
        const { resolve, reject } = callbacksDict[requestId];
        delete callbacksDict[requestId];

        if (status === "ok") {
            resolve && resolve(msg)
        } else {
            reject && reject(msg)
        }

        // console.log("signed value =", msg)

    });

    client.ports.executeSignedResponse.subscribe(([requestId, value, hasError]: [string, Object[], boolean]) => {
        const callbacks = callbacksDict[requestId];
        delete callbacksDict[requestId];
        if (hasError) return callbacks && callbacks.reject(value);
        callbacks && callbacks.resolve(value)
    });

    return octoVault;
};

window.octoVaultInit = octoVaultInit;